<template>
  <div>
    <ValidationObserver ref="observer">
      <b-form @submit.prevent="onSubmit" @reset="resetForm">
        <b-sidebar bg-variant="white" v-model="is" backdrop shadow>
          <template #header>
            <div
              class="d-flex justify-content-between align-items-center px-1 py-50 bg-light"
            >
              <h5 class="m-0">
                <strong>إضافة خدمة</strong>
              </h5>
              <b-button
                @click="is = false"
                size="sm"
                variant="flat-secondary"
                class="btn-icon rounded-circle"
              >
                <unicon name="times" width="16" height="16" />
              </b-button>
            </div>
          </template>
          <b-col cols="12">
            <BTextInputWithValidation
              rules="required"
              label="اسم الخدمة"
              name="اسم الخدمة"
              v-model="serviceDto.name"
              placeholder="أدخل اسم الخدمة"
            />
            <BTextInputWithValidation
              rules="required|integer|positive"
              label="رقم الخدمة"
              name="رقم الخدمة"
              type="number"
              v-model="serviceDto.order"
              placeholder="أدخل رقم الخدمة"
            />
            <label>صورة الخدمة</label>
            <fileDragDrop
              @inValidFile="unValidThumbImage"
              @uploaded="uploadThumbImage"
              id="employee-photo"
              height="100px"
              type="image"
              title="صورة جديدة"
            />
            <img
              v-if="!!serviceDto.base64"
              :src="serviceDto.base64"
              style="max-height: 320px; object-fit: fill"
              class="w-100"
            />
            <small class="text-danger">
              {{ !this.serviceDto.base64 ? "صورة الخدمات مطلوبة" : "" }}
            </small>
            <div class="editor-container mt-2">
              <quill-editor
                class="editor"
                ref="myTextEditor"
                :value="serviceDto.description"
                :options="editorOption"
                @change="onEditorChange"
              />
            </div>
            <small class="text-danger mt-5" v-show="!serviceDto.description"
              >شرح الخدمة مطلوب</small
            >
          </b-col>
          <template #footer>
            <div class="d-flex p-1 border-top">
              <b-button variant="outline-primary" class="ml-auto mr-1">إلغاء</b-button>
              <b-button variant="primary" type="submit">تم</b-button>
            </div>
          </template>
        </b-sidebar>
      </b-form>
    </ValidationObserver>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import fileDragDrop from "@core/components/file-drag-drop/file-drag-drop.vue";
import { ValidationObserver } from "vee-validate";
import BTextInputWithValidation from "@core/components/inputs/BTextInputWithValidation";
import { quillEditor } from "vue-quill-editor";
import hljs from "highlight.js";
export default {
  components: {
    BTextInputWithValidation,
    ValidationObserver,
    fileDragDrop,
    quillEditor,
  },
  data: () => ({
    editorOption: {
      modules: {
        toolbar: [
          ["bold", "italic", "underline", "strike"],
          ["blockquote", "code-block"],
          [{ header: 1 }, { header: 2 }],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ script: "sub" }, { script: "super" }],
          [{ indent: "-1" }, { indent: "+1" }],
          [{ direction: "rtl" }],
          [{ size: ["small", false, "large", "huge"] }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ font: [] }],
          [{ color: [] }, { background: [] }],
          [{ align: [] }],
          ["clean"],
          ["link", "image", "video"],
        ],
        syntax: {
          highlight: (text) => hljs.highlightAuto(text).value,
        },
      },
      placeholder: "ادخل شرح عن المرحلة",
    },
    serviceDto: {
      name: "",
      order: "",
      base64: "",
      description: "",
    },
  }),
  computed: {
    ...mapGetters({ servicesDetails: "servicesDetails", lang: "app/lang" }),
    is: {
      get() {
        return this.$store.getters["isCreateServiceModal"];
      },
      set(is) {
        this.$store.dispatch("setIsCreateServiceModal", is);
      },
    },
  },
  methods: {
    ...mapActions(["setNewService"]),
    resetForm() {
      Object.assign(this.servicesDetails, {
        id: 0,
        name: "",
        description: "",
        order: 0,
        language: this.lang,
        imageDto: {
          id: 0,
          path: "",
          base64: "",
        },
      });
      Object.assign(this.serviceDto, {
        name: "",
        order: "",
        base64: "",
        description: "",
      });
      requestAnimationFrame(() => {
        this.$refs.observer.reset();
      });
    },
    onEditorChange(value) {
      this.serviceDto.description = value.html;
    },
    uploadThumbImage(file) {
      
        this.serviceDto.base64 = file[0].base64;
    },
    onSubmit() {
      this.$refs.observer.validate().then((success) => {
        if (success && this.serviceDto.base64) {
          this.setNewService({
            name: this.serviceDto.name,
            order: +this.serviceDto.order,
            language: this.lang,
            imageDto: {
              id: 0,
              path: null,
              base64: this.serviceDto.base64,
            },
            description: this.serviceDto.description,
          });
          this.resetForm();
        }
      });
    },
    unValidThumbImage() {
      console.log("un valid Thumb file");
    },
  },
};
</script>
